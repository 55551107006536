import DiscoveryCompanionLogo from "../assets/png/discoverycompanionlogo.png";
import Upload from "../assets/svg/upload.svg";
import UploadTile from "../assets/svg/uploadTile.svg";
import bulkUpload from "../assets/svg/bulkupload.svg";
import { Col, Container, Row } from "react-bootstrap";
import TabSection from "../components/TabSection";
import { useEffect, useState } from "react"; 
import axios from "axios";
import "../pages/DashboardScreen.css";
import { useNavigate } from "react-router-dom";

const DashboardScreen = () => {
  const navigate = useNavigate();
  const [orgName, setOrgName] = useState("");
  const [tabType, settab] = useState("upload");
  const [fileDetails, setFiles] = useState({
    uploadFile: [],
    startDate: null,
    endDate: null,
  });
  const [error, setError] = useState("");
  const [loading,setLoading] = useState(false);
  const switchTab = (type) => {
    settab(type);
  };

  const getOrgName = () => {
    const localStore = JSON.parse(localStorage.getItem("USERDATA")) || {};
    if (Object.keys(localStore).length) {
      setOrgName(localStore.orgName && localStore.orgName);
    }
  }; 

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("USERDATA")) || {};
    const { executionArn } = userData;
    if (executionArn) {
      settab("uploadTile");
    }
    getOrgName();
  }, []);
  const handleDashboardClick = () => {
    navigate("/viewdashboard")
  }
  return (
    <Container className="p-0 h-100 section-wrapper" fluid>
      <section className="w-100">
        <Row className="w-100 d-flex justify-content-center">
          <Col
            sm={11}
            md={7}
            lg={5}
            className="d-flex  justify-content-between py-4 gap-2"
          >
            <div
              className={` rounded p-3  uploadTab ${
                tabType === "upload" ? "selectedTab" : "border"
              }`}
            >
              <div>
                <img src={Upload} alt="upload icon" />
              </div>
              <p className="m-0 mt-2 font-weight-bold">Upload Files</p>
              {tabType !== "upload" && <p className="m-0 blueText">Done</p>}
            </div>
            <div
              className={` rounded p-3  uploadTab ${
                tabType === "selectDates" ? "selectedTab" : "border"
              }`}
            >
              <div>
                <img src={bulkUpload} alt="bulkupload icon" />
              </div>
              <p className="m-0 mt-2 font-weight-bold">Select Dates</p>
              {tabType !== "selectDates" && tabType !== "upload" && (
                <p className="m-0 blueText">Done</p>
              )}
            </div>
            <div
              className={` rounded p-3  uploadTab ${
                tabType === "uploadTile" ? "selectedTab" : "border"
              }`}
            >
              <div>
                <img src={UploadTile} alt="uploadtile icon" />
              </div>
              <p className="m-0 mt-2 font-weight-bold">Review Output</p>
            </div>
          </Col>
        </Row>
        <Row className="w-100 d-flex justify-content-center">
          <Col sm={11} md={7} lg={5}>
            <TabSection
              type={tabType}
              fileDetails={fileDetails}
              setFiles={setFiles}
              switchTab={switchTab}
              setType={settab}
            />
          </Col>
        </Row>
        <Row className="w-100 d-flex justify-content-center">
        <div className="view-dashboard-path text-center" onClick={handleDashboardClick}>View Dashboards</div>
          <Col sm={3} md={3} lg={2} className="d-flex align-items-center">
            <div className="DiscoveryCompanionLogo-wrapper">
              <img 
                src={DiscoveryCompanionLogo}
                alt="DiscoveryCompanionLogo"
                width="100%"
              />
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default DashboardScreen;
