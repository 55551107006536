import axios from "axios";
import axiosInstance from "../../../axiosInterceptor";

export const getAuditRows = async (pageNo = 1, filters) => {
  let payload = "";
  console.log("PAYLOAD::::", filters);
  Object.entries(filters).map((filter) => {
    if (!filter[1]) {
      return;
    }
    if (typeof filter[1] === "string") {
      payload += `&${filter[0]}=${filter[1]}`;
      return;
    }
    filter[1].map((option) => {
      payload += `&${filter[0]}=${option}`;
    });
  });
  console.log("FILTERS:::::", { filters, payload }); 
  // const v4EndPoint =
  //   "https://x8zya18gei.execute-api.ap-southeast-2.amazonaws.com/filter_api_v4/"; 
  const v5EndPoint = 
  "https://4yge7xtq74.execute-api.ap-southeast-2.amazonaws.com/filter_api_v6/";
  return await axiosInstance
    .post(v5EndPoint, {
      body: {
        ...filters,
        pageNumber: pageNo,
      },
    })
    .then((response) => {
      const data = JSON.parse(response.data.body);
      if (typeof data?.message === "string") {
        throw new Error(response.data.message);
      }
      const auditRows = data.message.audited_rows;
      return auditRows;
    })
    .catch((error) => {
      console.error("Error in AuditRows Get request:", error);
      throw new Error(error.message);
    });
};

export const getauditData = async (id = 1, priority_value) => { 
  const getapiEndpointv3 = "https://xhkbjnw5a6.execute-api.ap-southeast-2.amazonaws.com/workflow_get_detailed_audit_data_v5/"
  return await axiosInstance
    .post(getapiEndpointv3, {
      body: {
        id,
        priority_value
      }
    })
    .then((response) => {
      const auditData = JSON.parse(response.data.body)
      return auditData.message;
    })
    .catch((error) => {
      console.error("Error in Auditdetail Get request:", error);
      throw new Error(error.message);
    });
};
export const getFilteredAuditData = async (filters) => {
  // const getapiEndpoint =
  //   "https://nw71l5ckl4.execute-api.ap-southeast-2.amazonaws.com/webwork_flow_filter_api/";
  // const v3EndPoint ="https://os3wl7iyn2.execute-api.ap-southeast-2.amazonaws.com/workflow_filter_api_v3/"
  // const v4EndPoint =
  //   "https://x8zya18gei.execute-api.ap-southeast-2.amazonaws.com/filter_api_v4/";
  const v5EndPoint = 
  "https://4yge7xtq74.execute-api.ap-southeast-2.amazonaws.com/filter_api_v6/";
  return await axiosInstance
    .post(v5EndPoint, {
      body: {
        ...filters,
      },
    })
    .then((response) => {
      if (response.data.errorMessage) {
        throw new Error(response.data.errorMessage);
      }
      const filteredAuditData = response.data.body;
      return JSON.parse(filteredAuditData);
    })
    .catch((error) => {
      console.error("Error in FilteredAuditdetail  request:", error);
      throw new Error(error.message);
    });
};

export const getSearchData = async (searchData) => {
  const url =
    "https://xykjyigf62.execute-api.ap-southeast-2.amazonaws.com/work_flow_search_by_episode_identifier_v2/";
  const params = {
    // episode_identifier: "I516064"
    episode_identifier: searchData,
  };
  return axiosInstance
    .get(url, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getSearchRecommendations = async (identifier) => {
  const url =
    "https://ficcgyavr8.execute-api.ap-southeast-2.amazonaws.com/web_work_flow_search_recomendation_episode_identifier_v2/";
  try {
    const response = await axiosInstance.get(url, {
      params: { episode_identifier: identifier },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const auditStatusUpdate = async (auditDetails) => { 
  // const urlv3 =
  //   "https://svga801o97.execute-api.ap-southeast-2.amazonaws.com/work_flow_edit_audit_row_v3/";
  const urlv4 = 
  "https://49jcxkmeqg.execute-api.ap-southeast-2.amazonaws.com/work_flow_edit_audit_row_v5/";
  const response = await axiosInstance.put(urlv4, { body: auditDetails });
  if (response.data.errorMessage) {
    throw new Error(response.data.errorMessage);
  }
  const message = JSON.parse(response.data.body);
  console.log(message, "res::::");
  return message;
};
export const getAuditFiles = async (pageNumber = 1) => {
  const url =
    "https://lbr4h9efic.execute-api.ap-southeast-2.amazonaws.com/get_audit_files_v2/";
  try {
    const response = await axiosInstance.get(url,{
      params: {
        pageNumber: pageNumber
      }
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPassedRecords = async (pageNo) => {
  const url = `https://8yrsa3a6tf.execute-api.ap-southeast-2.amazonaws.com/get_passed_records_v1/?pageNumber=${pageNo}`;
  try {
    const response = await axios.get(url);
    if (typeof response.data?.message === "string") {
      throw new Error(response.data.message);
    }
    return response.data.message.audited_rows;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPassedRecordsDetails = async (id) => {
  const url = `https://k5glluopp7.execute-api.ap-southeast-2.amazonaws.com/get_passed_records_detailed_v1/?id=${id}`;
  try {
    const response = await axios.get(url);
    return response.data.meesage;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getfilteroptionsdata = async () => {
  const url =
    "https://u9f8paiz9l.execute-api.ap-southeast-2.amazonaws.com/work_flow_tool_get_filter_options/";
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

// export const getAuditRowsByFileName =async (payload) => {
//   return fetch(
//     `https://noe45vvz63.execute-api.ap-southeast-2.amazonaws.com/get_audit_rows_filter_by_file_name/?${payload}`
//   )
//     .then((response) => {
//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }
//       return response.json();
//     })
//     .then((data) => {
//       const rows = data.meesage;
//       return rows;
//     })
//     .catch((error) => {
//       throw new Error(error.message);
//     });
// };
