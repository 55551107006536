import React from "react";
import { Loader } from "../../utils/util";
import chatIcon from "../../assets/chaticon.png";

const MessageLoader = () => {
  return (
    <div className="my-input-message">
      <div className="my-input-logo">
        <img src={chatIcon} alt="CompanionLogo" />
      </div>
      <div className="my-input-message-data">
        <span>AI Companion:</span>
        <p>
          <Loader />
          <Loader />
          <Loader />
        </p>
      </div>
    </div>
  );
};

export default MessageLoader;
