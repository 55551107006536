import React, { useState } from "react";
// import LikeIcon from "../../assets/like.jpg";
// import DisLikeIcon from "../../assets/dislike.jpg";
import ChatIcon from "../../assets/chaticon.png";
import {
  Button,
  Modal,
  OverlayTrigger,
  Popover,
  Table, 
} from "react-bootstrap";
import {
  // getRecommendationData,
  getReferencesWithPageNumber,
} from "../../api/Service";
import Thumsup from "../../assets/svg/Thumsup";
import ThumbsDown from "../../assets/svg/ThumbsDown"; 
import { useAuth } from "../../../../authContext/AuthContext";

const ReferenceModal = ({ reference }) => {
  const [popoverResponse, setpopoverResponse] = useState({
    content: "",
    page_number: [],
    reference_documents: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleReferenceClick = async (e, reference) => {
    e.preventDefault();
    const content = reference.reference_text;
    const s3Location = reference.source;
    try {
      setIsLoading(true);
      if (!popoverResponse.content) {
        const result = await getReferencesWithPageNumber(content, s3Location);
        console.log("RES::::::", result);
        const popoverdata = result.pages[0];

        setpopoverResponse(popoverdata);
        console.log("Reference Result:", popoverdata);
      }
    } catch (error) {
      console.error(
        "Error fetching references with page number:",
        error.message
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      overlay={
        <Popover id={`reference-${reference.source}`}>
          <Popover.Body>
            {/* <h6>{reference.source}</h6> */}
            <h6>Context</h6>
            <div className="ml-2">{reference.reference_text}</div>
            <h6>References:</h6>
            {isLoading ? (
              <h6 className="references-color">Loading ...</h6>
            ) : (
              <div className="references-color">
                <p>{popoverResponse.reference_documents.join(", ")}</p>
                <p>Page Number: {popoverResponse.page_number.join(", ")}</p>
              </div>
            )}
          </Popover.Body>
        </Popover>
      }
    >
      <button
        className="citations-button"
        onClick={(e) => handleReferenceClick(e, reference)}
      >
        {reference.citation_number}
      </button>
    </OverlayTrigger>
  );
};

export const Version2Message = ({ message }) => {
  console.log(message,'85::')
  return (
    <>
      {Object.keys(message.answer)?.map((key) => {
        const msz = message?.answer[key];
      console.log(msz,'90')
        return (
          <div>
            {msz?.answer}
           
            { msz?.references?.map((reference, i) => {
              return <ReferenceModal key={i} reference={reference} />;
            })}
          </div>
        );
      })}
    </>
  );
};

const VersionMessage = ({ message, handleLike, handleRecommendationClick ,handleThumbsDown}) => {
  console.log(message,"105::::")
  const isVersion2 = typeof message.answer === "object";
  return (
    <>
      <p style={{ whiteSpace: "pre-line" }}>
        {isVersion2 ? <Version2Message message={message} /> : message.answer}
      </p>
      <span
        className={`like-dislike-icon ${message.is_liked && "selected"}`}
        onClick={() =>
          handleLike(message.message_id, message.is_liked ? null : true)
        }
      >
        <Thumsup isLiked={message.is_liked}/>
      </span>
      <span
        className={`like-dislike-icon ${
          message.is_liked === false && "selected"
        }`}
        onClick={handleThumbsDown}
      >
        <ThumbsDown isLiked={message.is_liked}/>
      </span>
      {!isVersion2 && (
        <button
          style={{ float: "right" }}
          className="references-btn-version1"
          onClick={() => handleRecommendationClick(message.contents_list)}
        >
          References
        </button>
      )}
    </>
  );
};

const MessageCard = ({ message, updateLikeState }) => {
  const { userEmail } = useAuth();
  const [openRecommendationModal, setOpenRecommendaionModal] = useState(false);
  const [recommendationContent, setRecommendaionContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDislikeModal, setShowDislikeModal] = useState(false);
  const [dislikeComment, setDislikeComment] = useState("");
  const covertedFirstLetter = userEmail[0].toUpperCase();
  const handleLike = (message_id, is_liked,user_comment_on_message_response = "") => {
    console.log(message_id,is_liked,"handlelikeclicked::")
    const session_token = sessionStorage.getItem("accessToken");
    updateLikeState({ session_token, message_id, is_liked, user_comment_on_message_response });
  };
  const handleThumbsDown = () => {
    const addComment = window.confirm("Would you like to add a comment?");
    if (addComment) {
      setShowDislikeModal(true);
    } else {
      handleLike(message.message_id, false, null);
    }
  };

  const handleDislikeSubmit = () => {
    const comment = dislikeComment.trim() === "" ? null : dislikeComment;
    handleLike(message.message_id, false, comment);
    setShowDislikeModal(false);
    setDislikeComment("");
    alert("Feedback submitted succesfully!");
  };
  const handleRecommendationClick = async (contentList) => {
    try {
      setIsLoading(true);
      console.log({contentList},"159")
      const newList = contentList?.map(option => {
        const s3_location = option.s3_location.map(val => val.split("s3://genai-imedx-san/")[1]) ?? []
        return {
          content: option.content,
          s3_location
        }
      }) ?? []
      setOpenRecommendaionModal(true);
      const result = await getReferencesWithPageNumber(newList, "", true);
      if (result.error) {
        throw new Error(result.error)
      }
      if (result?.pages) setRecommendaionContent(result.pages);
      else setRecommendaionContent(result);
      console.log("RESPPP::", result)
      setIsLoading(false);
    } catch (err) {
      console.error("recommendations_error::", err.message)
    }
    
  };
  const messagedata = {...message.answer,message_id:message.message_id,is_liked:message.is_liked}
  return (
    <>
      {/* Displaying input */}
      <div className="my-input-message">
        <div className="my-input-logo">
          <p>{covertedFirstLetter}</p>
        </div>
        <div className="my-input-message-data">
          <span>You:</span>
          <p>{message.question}</p>
        </div>
      </div>

      {/* Displaying Response */}
      {message.answer && (
        <div className="my-input-message">
          <div className="my-input-logo">
            <img src={ChatIcon} alt="CompanionLogo" />
          </div>
          <div className="my-input-message-data">
            <span>AI Companion:</span>
            <VersionMessage
              message={messagedata}
              handleLike={handleLike}
              handleRecommendationClick={handleRecommendationClick}
              handleThumbsDown={handleThumbsDown}
            />
          </div>
        </div>
      )}
      {openRecommendationModal && (
        <Modal
          show={openRecommendationModal}
          onHide={() => setOpenRecommendaionModal(false)}
          className="recommendation-modal"
        >
          <Modal.Body>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                {/* <Spinner /> */}
                <h6>Loading ...</h6>
              </div>
            ) : (
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Content</th>
                    <th>Reference Documents</th>
                    <th>Page No.</th>
                  </tr>
                </thead>
                <tbody>
                {(recommendationContent.length > 0 && Array.isArray(recommendationContent))
                    ? recommendationContent.map((contentdata, i) => (
                        <tr key={i}>
                          <td>{contentdata.content}</td>
                          <td>{contentdata.reference_documents}</td>
                          <td>{contentdata.page_number.join(",")}</td>
                        </tr>
                      ))
                    :<span>{recommendationContent}</span>}
                </tbody>
              </Table>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setOpenRecommendaionModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Dislike Modal */}
      <Modal
        show={showDislikeModal}
        onHide={() => setShowDislikeModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Why did you dislike this answer?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            className="form-control"
            rows="4"
            placeholder="Please provide a detailed explanation of why the AI didn't answer as per your expectation."
            value={dislikeComment}
            onChange={(e) => setDislikeComment(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDislikeModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDislikeSubmit}>
            Send Feedback
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MessageCard;
