import React, { useState } from "react";
import Login from "../pages/login/Login";
import Signup from "../pages/signup/SignUp";

const AuthComponent = () => {
  const [showLogin, setShowLogin] = useState(true);
  return showLogin ? (
    <Login setShowLogin={setShowLogin} />
  ) : (
    <Signup setShowLogin={setShowLogin} />
  );
};

export default AuthComponent;
