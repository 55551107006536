import SendIcon from "../assets/send.png";

export const initialMessage = [{
    id: 1,
    title: "Provide an overview of query formats with a multiple choice format sample relating to postprocedural adhesions",
    description: "",
    imgSrc: SendIcon,
    altContent: "sendicon"
},{
    id:2,
    title: "Is it acceptable to use elevated non-fasting triglycerides to inform the assignment of diabetes mellitus or intermediate hyperglycaemia with features of insulin resistance?",
    description: "",
    imgSrc: SendIcon,
    altContent: "sendIcon"
},{
    id: 3,
    title: "Provide a list of ICD-10 disease codes that must never be assigned for inpatient morbidity coding in Australian Health care setting",
    description: "",
    imgSrc: SendIcon,
    altContent: "sendIcon"
},{
    id:4,
    title: "What codes should be applied to sepsis or septic shock?",
    description: "",
    imgSrc: SendIcon,
    altContent: "sendIcon"
}]