import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { fileTypes, prorityValues, statusTypes } from "./config";
import { getfilteroptionsdata } from "../../api/Service";
import { formatCalenderDate } from "../../utils/util";

const currentDate = formatCalenderDate(new Date());
const dateFormat = "yyyy-MM-dd"


const WorkFlowFilters = ({ showModal, onApply, onCancel }) => {
    
  const [coderNames, setCoderNames] = useState([]);
  const [insurerIdentifierList, setInsurerIdentifierList] = useState([]);
  const [toggleUnitConversion, setToggleUnitConversion] = useState(false);
  const [filtersObj, setFiltersObj] = useState({
    fileType: [],
    priorityValue: [],
    status: [],
    insurerIdentifier: [],
    codedBy: [],
    fromAdmissionDate: "",
    toAdmissionDate: "",
    fromSeparationDate: "",
    toSeparationDate: "",
  });
  const handleCheckboxChange = (name, value) => {
    const currentIndex = filtersObj[name].indexOf(value);
    const newChecked = [...filtersObj[name]];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFiltersObj({...filtersObj, [name]: newChecked})
  };
  const handleFiltersApply = () => {
    const filters = {
        insurer_identifier: filtersObj.insurerIdentifier,
        priority_value: filtersObj.priorityValue,
        status: filtersObj.status,
        file_type: filtersObj.fileType,
        coder_name: filtersObj.codedBy,
        admission_date_from: formatCalenderDate(filtersObj.fromAdmissionDate),
        admission_date_to: filtersObj.fromAdmissionDate
          ? filtersObj.toAdmissionDate
            ? formatCalenderDate(filtersObj.toAdmissionDate)
            : currentDate
          : "",
        seperation_date_from: formatCalenderDate(filtersObj.fromSeparationDate),
        seperation_date_to: filtersObj.fromSeparationDate
          ? filtersObj.toSeparationDate
            ? formatCalenderDate(filtersObj.toSeparationDate)
            : currentDate
          : "",
      };
    onApply(filters)
  };
  const handleDateChange = (name, date) => {
    setFiltersObj({...filtersObj, [name]: date})
  };
  useEffect(() => {
    getfilteroptionsdata().then((res) => {
      const { coder_name, insurer_identifier } = res.message;
      const formatedCoderNames = coder_name.map((name, i) => ({
        id: i + 1,
        name: name[0].trim(),
      }));
      const formattedInsurerIdentifier = insurer_identifier.map((name, i) => ({
        id: i + 1,
        name: name[0],
      }));
      setCoderNames(formatedCoderNames);
      setInsurerIdentifierList(formattedInsurerIdentifier);
    }).catch((error)=>{
      if(error.response && (error.response.status===401 || error.response.status===403)){
        let errorMessage='';
        if(error.response.status===401){
          errorMessage='Please login again'
        }else if(error.response.status===403){
          errorMessage='You do not have permission to access this resource'
        }
        window.dispatchEvent(new CustomEvent('tokenExpired', {detail:{errorMessage}}));
      }
    });
  }, []);
  const handleUnitCoversionToggle =() =>{
    setToggleUnitConversion(!toggleUnitConversion)
  }
  return (
    <div
      className={`modal fade ${showModal ? "show" : ""}`}
      style={{ display: showModal ? "block" : "none" }}
      tabIndex="-1"
    >
      <div
        className="modal-dialog"
        style={{ maxWidth: "100%", overflow: "auto" }}
      >
        <div
          className="modal-content"
          style={{ width: "1352px", margin: "auto" }}
        >
          <div className="modal-body filter-modal-body">
            <div className="d-flex">
              {/* File Type */}
              <div className="filters-border">
                <h6 className="text-start">File Type</h6>
                <div className="file-type-wrapper">
                  {fileTypes.map((fileType) => (
                    <div key={fileType} className="checkbox-container">
                      <input
                        type="checkbox"
                        id="fileType1"
                        onChange={() =>
                          handleCheckboxChange("fileType", fileType)
                        }
                        checked={filtersObj.fileType.includes(fileType)}
                      />
                      <label htmlFor="fileType1">{fileType}</label>
                    </div>
                  ))}
                </div>
              </div>
              {/* Priority Value */}
              <div className="filters-border">
                <h6 className="text-start">Priority Value <button className="toggle-conversion-btn" onClick={handleUnitCoversionToggle}>$ / NWAU</button></h6>
                <div className="">
                  {prorityValues.map((priority) => (
                    <div key={priority.label} className="checkbox-container">
                      <input
                        type="checkbox"
                        id={`priority${priority.value}`}
                        onChange={() =>
                          handleCheckboxChange("priorityValue", priority.value)
                        }
                        checked={filtersObj.priorityValue.includes(priority.value)}
                      />
                      <label htmlFor={`priority${priority.value}`}>
                      {toggleUnitConversion? priority.label:priority.nwunits || priority.label}
                      </label>
                    </div>
                  ))}
                   {!toggleUnitConversion && <h6>1 NWAU = $5,668.</h6>}
                </div>
              </div>
              {/* Status */}
              <div className="filters-border">
                <h6 className="text-start">Select Status</h6>
                <div className="">
                  {statusTypes.map((type) => (
                    <div key={type} className="checkbox-container">
                      <input
                        type="checkbox"
                        id={`statusType-${type}`}
                        onChange={() => handleCheckboxChange("status", type)}
                        checked={filtersObj.status.includes(type)}
                      />
                      <label htmlFor={`statusType-${type}`}>{type}</label>
                    </div>
                  ))}
                </div>
              </div>
              {/* Insurer Identifier */}
              <div
                className="filters-border"
                style={{
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <h6 className="text-start">Select Insurer Identifier</h6>
                <div className="checkbox-grid">
                  {insurerIdentifierList.map((item) => (
                    <div key={item.id} className="checkbox-container">
                      <input
                        type="checkbox"
                        id={`insurer-${item.id}`}
                        value={item.id}
                        checked={filtersObj.insurerIdentifier.includes(
                          item.name
                        )}
                        onChange={(e) =>
                          handleCheckboxChange("insurerIdentifier", item.name)
                        }
                      />
                      <label
                        htmlFor={`insurer-${item.id}`}
                        style={{ marginLeft: "8px" }}
                      >
                        {item.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="filters-border">
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                  }}
                >
                  <h6 className="text-start">Coded By</h6>
                  <div>
                    {coderNames?.map((item) => (
                      <div key={item.id} className="checkbox-container">
                        <input
                          type="checkbox"
                          id={`codedby-${item.id}`}
                          value={item.id}
                          checked={filtersObj.codedBy.includes(item.name)}
                          onChange={(e) =>
                            handleCheckboxChange("codedBy", item.name)
                          }
                        />
                        <label
                          htmlFor={`codedby-${item.id}`}
                          style={{ marginLeft: "8px" }}
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Dates */}
              <div className="dates-wrapper">
                <h6 className="text-start">Admission Date:</h6>
                <div className="date-picker-wrapper">
                  <DatePicker
                    selected={filtersObj.fromAdmissionDate}
                    onChange={(dt) => handleDateChange("fromAdmissionDate", dt)}
                    dateFormat={dateFormat}
                    placeholderText="From"
                    maxDate={currentDate}
                  />
                  <DatePicker
                    selected={filtersObj.toAdmissionDate}
                    onChange={(dt) => handleDateChange("toAdmissionDate", dt)}
                    dateFormat={dateFormat}
                    placeholderText="To"
                    minDate={filtersObj.fromAdmissionDate}
                    maxDate={currentDate}
                  />
                </div>
                <h6 className="text-start date-header">Separation Date:</h6>
                <div className="date-picker-wrapper">
                  <DatePicker
                    selected={filtersObj.fromSeparationDate}
                    onChange={(dt) => handleDateChange("fromSeparationDate", dt)}
                    dateFormat={dateFormat}
                    placeholderText="From"
                    minDate={filtersObj.fromAdmissionDate}
                    maxDate={currentDate}
                  />
                  <DatePicker
                    selected={filtersObj.toSeparationDate}
                    onChange={(dt) => handleDateChange("toSeparationDate", dt)}
                    dateFormat={dateFormat}
                    placeholderText="To"
                    minDate={filtersObj.fromSeparationDate}
                    maxDate={currentDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer filter-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCancel}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              style={{ backgroundColor: "#4298CD", border: "none" }}
              onClick={handleFiltersApply}
            >
              {/* <span>
              <img src={filtertick} alt="Filter Tick" />
            </span>{" "} */}
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkFlowFilters;
 