import React, { useEffect, useState } from "react";
import Logo from "../assets/png/Pallasai_logo_white.png";
import Logo2 from "../assets/png/CoderCompanion_logo_white.png";
import { useNavigate } from "react-router-dom";
import { Dropdown, Form } from "react-bootstrap";
import "./ViewDashboard.css";
import { getAuditFiles, getPowerBIFiles } from "../api/Service";
import axios from "axios";
import RcmOverview from "./rcm-overview";
import Findings from "./findings";
import CodingImprovements from "./coding-improvements";
import FocusArea from "./focus-area";
import axiosInstance from "../../../axiosInterceptor";

const getFilterFields = (type) => {
  switch (type) {
    case "FINDINGS": {
      return [
        "audit_rule_category",
        "audit_rule_ref_no",
        "principal_diagnosis_code",
        "priority_value",
        "current_drg_revenue",
        "coder_name",
        "drg_code",
      ];
    }
    case "FOCUS_AREA": {
      return [
        "audit_rule_category",
        "audit_rule_short_description",
        "audit_rule_long_description",
        "coder_name",
      ];
    }
    case "CODING_IMPROVMENTS": {
      return [
        "drg_code",
        "principal_diagnosis_code",
        "procedure_codes1",
        "procedure_codes2",
        "procedure_codes3",
        "procedure_codes4",
        "procedure_codes5",
        "procedure_codes6",
        "procedure_codes7",
        "procedure_codes8",
        "procedure_codes9",
        "procedure_codes10",
        "audit_rule_user_message",
        "audit_drg_revenue",
        "coder_name",
      ];
    }
    case "RCM_OVERVIEW": {
      return [
        "priority_value",
        "principal_diagnosis_code",
        "care_type",
        "coder_name",
      ];
    }
    default:
      return [];
  }
};

function ViewDashboard() {
  const backNavigate = useNavigate();
  const [activeTab, setActiveTab] = useState("RCM_OVERVIEW");
  const [auditFiles, setAuditFiles] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [loadId, setLoadId] = useState([]); 
  useEffect(() => {
    const fetchAuditFiles = async () => {
      try {
        const response = await getPowerBIFiles();
        setAuditFiles(response.message.audited_files);
        console.log(response.message.audited_files, 'line 80 sagar')
      } catch (error) {
        console.error("Error fetching audit files:", error);
      }
    };
    fetchAuditFiles();
    fetchData();
  }, []);

  const handleBackClick = () => {
    backNavigate(-1);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    fetchData([], tab);
  };

  const handleSelectChange = (event) => {
    const { value, checked } = event.target;
    let updatedSelectedOptions;
    if (checked) {
      updatedSelectedOptions = [...selectedOptions, value];
    } else {
      updatedSelectedOptions = selectedOptions.filter(
        (option) => option !== value
      );
    }
    setSelectedOptions(updatedSelectedOptions);
    setLoadId(updatedSelectedOptions);
    fetchData(updatedSelectedOptions);
  };

  const fetchData = (selectedFields = [], tab = activeTab) => { 
    setIsLoading(true);
    const payload = {
      load_id: selectedFields,
      fields: getFilterFields(tab),
    };
    axiosInstance
      .post(
        "https://o0x81qtsqc.execute-api.ap-southeast-2.amazonaws.com/power_bi_get_filter/",
        payload
      )
      .then((response) => {
        console.log(response.data, "response::");
        const parsedData = JSON.parse(response.data.body);
        if (typeof parsedData.message === "string") {
          throw new Error(parsedData.message);
        }
        setFilterOptions(parsedData.message);
      })
      .catch((error) => {
        console.error("There was a problem with the axios operation:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allOptions = auditFiles.map((item) => item.load_id);
      setSelectedOptions(allOptions);
      setLoadId(allOptions);
      fetchData(allOptions);
    } else {
      setSelectedOptions([]);
      fetchData([]);
    }
  };

  const getDropdownLabel = () => {
    if (selectedOptions.length === auditFiles.length) {
      return "Select All";
    } else if (selectedOptions.length > 0) {
      const firstSelected = auditFiles.find(
        (item) => item.load_id === selectedOptions[0]
      );
      return firstSelected
        ? `${firstSelected.start_date} - ${firstSelected.end_date} ${firstSelected.load_id}`
        : "Select All";
    }
    return "Select All";
  };

  return (
    <>
      <div>
        <div className="cerebrumNav d-flex"> 
          <div className="toggle-btns d-flex gap-2">
            <span
              onClick={() => handleTabClick("RCM_OVERVIEW")}
              className={activeTab === "RCM_OVERVIEW" ? "active" : ""}
            >
              RCM Overview
            </span>
            <span
              onClick={() => handleTabClick("FINDINGS")}
              className={activeTab === "FINDINGS" ? "active" : ""}
            >
              Findings
            </span>
            <span
              onClick={() => handleTabClick("CODING_IMPROVMENTS")}
              className={activeTab === "CODING_IMPROVMENTS" ? "active" : ""}
            >
              Coding Improvements
            </span>
            <span
              onClick={() => handleTabClick("FOCUS_AREA")}
              className={activeTab === "FOCUS_AREA" ? "active" : ""}
            >
              Focus Area
            </span>
          </div>
          <div className="files-dropdown">
            {/* <label className="select-hcp-files">
              Select HCP Files:
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="orange"
                class="bi bi-caret-right"
                viewBox="0 0 16 16"
              >
                <path d="M6 12.796V3.204L11.481 8z" fill="orange" />
                <path d="M6.659 13.549l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
              </svg>
            </label> */}
            <Dropdown className="hcp-file-dropdown-powerbi">
              <Dropdown.Toggle id="dropdown-basic">
                {getDropdownLabel()}
              </Dropdown.Toggle>
              <Dropdown.Menu className="hcp-dropdown-menu">
                <Form>
                  <Form.Check
                    type="checkbox"
                    label="Select All"
                    checked={selectedOptions.length === auditFiles.length}
                    onChange={handleSelectAllChange}
                  />
                  {auditFiles.map((item) => (
                    <Form.Check
                      key={item.load_id}
                      type="checkbox"
                      label={`${item.start_date} - ${item.end_date} ${item.load_id}`}
                      value={item.load_id}
                      checked={selectedOptions.includes(item.load_id)}
                      onChange={handleSelectChange}
                      style={{ width: "380px" }}
                    />
                  ))}
                </Form>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="tab-content-wrapper">
        <div className="tab-content">
          {activeTab === "RCM_OVERVIEW" && (
            <RcmOverview loadId={loadId} filterOptions={filterOptions} />
          )}
          {activeTab === "FINDINGS" && (
            <Findings filterOptions={filterOptions} loadId={loadId} />
          )}
          {activeTab === "CODING_IMPROVMENTS" && (
            <CodingImprovements loadId={loadId} filterOptions={filterOptions} />
          )}
          {activeTab === "FOCUS_AREA" && (
            <FocusArea loadId={loadId} filterOptions={filterOptions} />
          )}
        </div>
      </div>
    </>
  );
}

export default ViewDashboard;
