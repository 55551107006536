import "react-datepicker/dist/react-datepicker.css";
import DashboardScreen from "./pages/DashboardScreen";
import "./index.css"
// import { SnackbarProvider } from "notistack";

function CoderCompanion() {
  return (
    
    <DashboardScreen />
  );
}

export default CoderCompanion;
