import React, { useEffect, useState } from "react";
import "./FocusArea.css";
import MultiSelectDropdown from "../../components/multiselect-dropdown/MultiSelectDropdown";
import { getFocusAreasData } from "../../api/Service";
import InfiniteScroll from "../../components/infinite-scroll";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs";

const headers = [
  { key: "audit_rule_category", value: "Audit Rule Category" },
  {
    key: "audit_rule_short_description",
    value: " Audit Rule Short Description",
  },
  { key: "audit_rule_long_description", value: " Audit Rule Long Description" },
  { key: "count", value: "Count Of Episodes" },
];

const intialFiletrsObj = {
  ruleCategory: [],
  shortDescription: [],
  longDescription: [],
  coderName: [],
};
const getObject = (option) => ({
  label: option,
  value: option,
});

const FocusArea = ({ loadId, filterOptions }) => {
  const [filterToggle, setFilterToggle] = useState();
  const [hospitalRevenuePrefinding, setHospitalRevenuePrefinding] = useState(0);
  const [potentialRevenueUplift, setPotentialRevenueUplift] = useState(0);
  const [filtersData, setFiltersData] = useState(intialFiletrsObj);
  const [selectedFilters, setSelectedFilters] = useState(intialFiletrsObj);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [tableData, setTableData] = useState([]);
  const formatToMillions = (num) => {
    return "$" +(num / 1_000_000).toFixed(2) + 'M';
  };
  const formatNumber = (num) => {
    return '$' + num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };
  const handleSelectFilter = (name, selectedOptions) => {
    console.log("SELECTED:::", name, selectedOptions);
    setSelectedFilters((prev) => ({
      ...prev,
      [name]: selectedOptions.map((option) => option.value),
    }));
  };
  const getData = (page = currentPage, loadMore) => {
    console.log("PAGE::::", page);
    const body = {
      audit_rule_short_description: selectedFilters.shortDescription,
      audit_rule_category: selectedFilters.ruleCategory,
      audit_rule_long_description: selectedFilters.longDescription,
      coder_name: selectedFilters.coderName,
      pageNumber: page,
      load_id: loadId,
    };
    setIsLoading(true);
    getFocusAreasData(body)
      .then((res) => {
        const {
          hospital_revenue_prefinding,
          potential_revenue_uplift,
          audited_rows,
        } = res;
        if (loadMore) {
          setTableData([...tableData, ...audited_rows]);
        } else {
          setTableData(audited_rows);
        }

        setHasMore(true);
        setHospitalRevenuePrefinding(hospital_revenue_prefinding);
        setPotentialRevenueUplift(potential_revenue_uplift);
        const newPage = page + 1;
        setCurrentPage(newPage);
      })
      .catch((err) => {
        console.log("FOCUS_AREA_GET_DATA_ERR::", err.message);
        setHasMore(false);
        if (!loadMore) {
          setTableData([]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getData(1);
  }, [selectedFilters, loadId]);
  useEffect(() => {
    if (Object.keys(filterOptions).length > 0) {
      const data = {
        ruleCategory: filterOptions?.audit_rule_category?.map(getObject),
        shortDescription:
          filterOptions.audit_rule_short_description?.map(getObject),
        longDescription:
          filterOptions.audit_rule_long_description?.map(getObject),
        coderName: filterOptions.coder_name?.map(getObject),
      };
      setFiltersData(data);
    }
  }, [filterOptions]);

  return (
    <div className="focus-area-Wrapper">
      <div className="top-calculations">
        <div className="hospital-revenue-findings">
          <div style={{ display: "inline-block" }}>
            {" "}
            Hospital Revenue Pre-findings{" "}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Sum of Current DRG Revenue</Tooltip>}
            >
              <span style={{ cursor: "pointer" }}>
                <BsInfoCircle />
              </span>
            </OverlayTrigger>
          </div>
          <div style={{ color: "#032F49" }}>{`${formatNumber(hospitalRevenuePrefinding)}.00`}</div>
        </div>
        <div className="Potential-revenue-uplift">
          <div style={{ display: "inline-block" }}>
            {" "}
            Potential Revenue Uplift{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  With an estimated average strike rate of 15% at an average DRG
                  uplift of $4K
                </Tooltip>
              }
            >
              <span style={{ cursor: "pointer" }}>
                <BsInfoCircle />
              </span>
            </OverlayTrigger>
          </div>
          <div style={{ color: "#032F49" }}>{formatToMillions(potentialRevenueUplift)}</div>
        </div>
      </div>
      <div className="focus-content-wrapper d-flex">
        <div className="focus-area-table-wrapper">
          <InfiniteScroll
            className="focus-area-table table-container"
            hasData={hasMore}
            loadMore={() => getData(currentPage, true)}
            isLoading={isLoading}
          >
            <h4 className="Focus-area-heading">Focus Area</h4>
            <table>
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index}>{header.value}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData?.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {headers.map((header) => (
                      <td key={header.key}>
                        <OverlayTrigger
                          placement="left"
                          overlay={<Tooltip>{row[header.key]}</Tooltip>}
                        >
                          <span>{row[header.key]}</span>
                        </OverlayTrigger>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
        <div className="d-flex mt-2 gap-1">
          <div onClick={handleFilterToggle} className="filters-text">
            <span>filters</span>
          </div>
          {filterToggle && (
            <div className="focus-filter-content p-2">
              <div className="filter-header d-flex gap-5">
                <h6>Filter Pane</h6>
                <span
                  className="back-arrow-filter"
                  onClick={handleFilterToggle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-return-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"
                    />
                  </svg>
                </span>
              </div>
              <div className="text-start mb-3">
                Audit Rule Category
                <MultiSelectDropdown
                  options={filtersData.ruleCategory}
                  onSelect={(options) =>
                    handleSelectFilter("ruleCategory", options)
                  }
                />
              </div>
              <div className="text-start mb-3">
                Audit Rule Short Description
                <MultiSelectDropdown
                  options={filtersData.shortDescription}
                  onSelect={(options) =>
                    handleSelectFilter("shortDescription", options)
                  }
                />
              </div>
              <div className="text-start mb-3">
                Audit Rule Long Description
                <MultiSelectDropdown
                  options={filtersData.longDescription}
                  onSelect={(options) =>
                    handleSelectFilter("longDescription", options)
                  }
                />
              </div>
              <div className="text-start">
                Coder Name
                <MultiSelectDropdown
                  options={filtersData.coderName}
                  onSelect={(options) =>
                    handleSelectFilter("coderName", options)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FocusArea;
