import { useEffect, useRef, useState } from "react";
import { Spinner } from "../../../web-workflow/common/Helper";

const InfiniteScroll = ({
  children,
  className,
  loadMore,
  hasData,
  isLoading,
  isTopScroll = false,
}) => {
  const divRef = useRef();
  useEffect(() => {
    const handleScroll = (e) => {
      const { scrollHeight, scrollTop, clientHeight } = divRef.current;
      if (isTopScroll && hasData && !isLoading) {
        if (scrollTop === 0) {
          loadMore();
        }
        return;
      }

      if (
        scrollTop + clientHeight >= scrollHeight - 1 &&
        hasData &&
        !isLoading
      ) {
        console.log("LOAD_DATA:::", {
          scrollHeight,
          scrollTop,
          clientHeight,
        });
        loadMore();
      }
    };
    if (divRef.current) {
      divRef?.current?.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (divRef.current) {
        divRef?.current?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [divRef.current, hasData, isLoading]);
  return (
    <div ref={divRef} className={className}>
      {isTopScroll && isLoading && <Spinner />}
      {children}
      {!isTopScroll && (
        <NoMoreDataComponent isLoading={isLoading} hasData={hasData} />
      )}
    </div>
  );
};
export default InfiniteScroll;

export const NoMoreDataComponent = ({ isLoading, hasData }) => {
  const [message, setMessage] = useState("No more Data");
  return (
    <>
      {isLoading && (
        <div>
          <Spinner />
        </div>
      )}
      {!hasData && <div className="text-danger">{message}</div>}
    </>
  );
};
