import React from "react";
import "./AuditRows.css";
import { extractFileName, extractUserName } from "../../utils/util"; 
import InfiniteScroll from "../../../codercompanion/components/infinite-scroll";

const getColor =(status) => {
  console.log(status,"statuscolor:::")
  const red =  ["Flagged for Review","Reviewed,no change","Reviewed,query required" ] // #F8698521;
  const blue = ["Reviewed,reclaimed", "Reviewed,rebilled", "Reviewed,query required"] // #00395B
  const gray = ["Reviewed,query sent", "Reviewed,recoded"] // #344054;
  const passed  = 'passed' //#E4F5FF
  const failed = "Failed" //#feecef;
  if (passed  === status) {
    return "passed"
  }
  if (failed === status) {
    return "failed"
  }
 
  if (red.includes(status)) return "red"
  if (blue.includes(status)) return "blue"
  if (gray.includes(status)) return "gray"

}

const AuditRowCard = ({ handleRowClick, row, isActive }) => { 
  return (
    <>
      <tr
        className={`auditrow ${isActive && "active"}`}
        onClick={() => handleRowClick(row)}
      >
        <td>
          <input type="checkbox" />
        </td>
        <td>
          {" "}
          <span className={`audit-count p${row.priority_value}`}>
            P{row.priority_value}
          </span>
        </td>
        <td>{extractFileName(row.file_name)}</td>
        <td>{extractUserName(row.name)}</td>
        <td>{row.date}</td>
        <td>{row.audit_rule_short_description}</td>
        <td>
          <div className={`audit-status-btn ${getColor(row.audit_status)}`}>
            {row.audit_status}
          </div>
        </td>
      </tr>
    </>
  );
};

function AuditRows({
  handleRowClick,
  auditRows,
  selectedIndex,
  hasMore,
  loadMore,
  isRowsLoading,
}) {
  return (
    <>
      <InfiniteScroll
        className=" audits-main"
        hasData={hasMore}
        loadMore={() => loadMore()}
        isLoading={isRowsLoading}
      > 
        <table id="audits-main" className="">
          <thead>
            <tr>
              <th>
                <input type="checkbox" />
              </th>
              <th>Select All</th>
              <th>Document Name</th>
              <th>User Name</th>
              <th>Date</th>
              <th>Description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {auditRows.map((row, idx) => (
              <AuditRowCard
                key={row.id}
                row={row}
                handleRowClick={handleRowClick}
                isActive={selectedIndex === idx}
              />
            ))}

          </tbody>
        </table>
      </InfiniteScroll>
    </>
  );
}

export default AuditRows;
