import React, { useState } from "react";
import "./SignUp.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { signUp } from "../../authContext/CognitoAuth";
import Logo from "../../common/assets/images/imdexlogo.png";
import { BiLoader } from "react-icons/bi";

function Signup({ setShowLogin }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    signUp(username, password, email, {
      onSuccess: (result) => {
        console.log("Sign-up successful:", result);

        const apiEndpoint =
          "https://4oygef93tj.execute-api.ap-southeast-2.amazonaws.com/llm_signup/";

        const payload = {
          body: {
            user_name: username,
            password_hash: password,
            email: email,
          },
        };

        axios
          .post(apiEndpoint, payload)
          .then((response) => {
            console.log("POST request successful:", response.data);
            navigate("/");
            alert("Account Created successfully");
            setUsername("");
            setEmail("");
            setPassword("");
            setError("");
            setShowLogin(true);
          })
          .catch((error) => {
            console.error("Error in POST request:", error);
            setError(error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      onFailure: (err) => {
        setError(err.message);
        setLoading(false);
      },
    });
  };

  return (
    <div className="signup-container"> 
      <form className="signup-form" onSubmit={handleSubmit}>
        <h2>Sign Up</h2>
        <div className="input-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setError("");
            }} 
          />
        </div>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError("");
            }} 
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError("");
            }}
          />
        </div>
        <button className="signup-btn btn btn-warning" type="submit">
          {loading && !error ? <BiLoader /> : "Sign Up"}
        </button>
        {error && <p className="signup-errormessage">{error}</p>}
        <div>
          <p className="switchLogin">
            Already have an account?{" "}
            <span onClick={() => setShowLogin(true)}>Login</span>
          </p>
        </div>
      </form>
    </div>
  );
}

export default Signup;
