export const Spinner = () => {
  return (
    <div className="text-center mx-auto" style={{overflow:"hidden"}}>
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
     </div>
  );
};
