import React from "react";
import { Modal,Button } from "react-bootstrap";
import exclamation_icon from './assets/exclamation.svg'

const ErrorModalPopup = ({show,handleModalClose,message}) => {
  return (
    <>
      <Modal
       show={show}
       onHide={handleModalClose}
       backdrop='static'
       keyboard={false}
       style={{textAlign:'center'}}
      >
       <Modal.Body style={{padding:'20px 20px 0px 20px'}}>
        <img src={exclamation_icon} alt="exclaim" style={{height:'100px'}}/>
        <h4 style={{marginBottom:'20px'}}>Session Time out!</h4>
        {message}
        </Modal.Body>
       <Modal.Footer style={{borderTop:'none',padding:'20px'}}>
         <Button style={{margin:'0px',backgroundColor:'#032f49',border:'none'}} variant="primary" onClick={handleModalClose}>Login</Button>
       </Modal.Footer>
      </Modal>
    </>
  );
};
export default ErrorModalPopup;
