const feedbackFormConfig = [
    {
      section: "General Information",
      fields: [
        {
          label: "User Name (Optional)",
          name: "userName",
          type: "text",
          placeholder: "Enter your name",
          optional: true,
        },
        {
          label: "Email Address (Required)",
          name: "email",
          type: "email",
          placeholder: "Enter your email",
          optional: false,
        },
        {
          label: "Date of Submission",
          name: "dateOfSubmission",
          type: "text",
          disabled: true,
          optional: false,
        },
        {
          label: "Browser or App Version (Optional)",
          name: "browserOrAppVersion",
          type: "text",
          placeholder: "Enter version",
          optional: true,
        },
      ],
    },
    {
      section: "User Feedback",
      fields: [
        {
          label: "Feedback Type",
          name: "feedbackType",
          type: "select",
          options: [
            { value: "", label: "Select Type" },
            { value: "bug", label: "Bug" },
            { value: "enhancement", label: "Product Enhancement" },
            { value: "suggestion", label: "Suggestion" },
            { value: "comment", label: "Comment" },
          ],
          optional: false,
        },
        {
          label: "Feedback Description",
          name: "feedbackDescription",
          type: "textarea",
          placeholder: "Describe your feedback",
          optional: false,
        },
        {
          label: "User Satisfaction Rating",
          name: "userSatisfactionRating",
          type: "rating",
          optional: false,
        },
      ],
    },
    {
      section: "Product Enhancement",
      fields: [
        {
          label: "Feature Request Title",
          name: "featureRequestTitle",
          type: "text",
          placeholder: "Enter feature title",
          optional: true,
        },
        {
          label: "Enhancement Description",
          name: "enhancementDescription",
          type: "textarea",
          placeholder: "Describe the enhancement",
          optional: true,
        },
        {
          label: "Importance / Priority",
          name: "importance",
          type: "select",
          options: [
            { value: "", label: "Select Priority" },
            { value: "low", label: "Low" },
            { value: "medium", label: "Medium" },
            { value: "high", label: "High" },
          ],
          optional: false,
        },
        {
          label: "Business Impact / Use Case",
          name: "businessImpact",
          type: "textarea",
          placeholder: "Describe the business impact",
          optional: true,
        },
      ],
    },
    {
      section: "Bug Reporting",
      fields: [
        {
          label: "Bug Title",
          name: "bugTitle",
          type: "text",
          placeholder: "Enter bug title",
          optional: true,
        },
        {
          label: "Bug Description",
          name: "bugDescription",
          type: "textarea",
          placeholder: "Describe the bug",
          optional: true,
        },
        {
          label: "Expected Behaviour",
          name: "expectedBehaviour",
          type: "text",
          placeholder: "What did you expect?",
          optional: true,
        },
        {
          label: "Actual Behaviour",
          name: "actualBehaviour",
          type: "text",
          placeholder: "What actually happened?",
          optional: true,
        },
        {
          label: "Severity / Impact Level",
          name: "severity",
          type: "select",
          options: [
            { value: "", label: "Select Severity" },
            { value: "minor", label: "Minor" },
            { value: "major", label: "Major" },
            { value: "critical", label: "Critical" },
          ],
          optional: false,
        },
        {
          label: "Attachments",
          name: "attachments",
          type: "file",
          optional: true,
        },
      ],
    },
    {
      section: "Additional Details",
      fields: [
        {
          label: "Consent for Follow-Up",
          name: "consentFollowUp",
          type: "checkbox",
          optional: true,
        },
        {
          label: "Submit Anonymously",
          name: "submitAnonymously",
          type: "checkbox",
          optional: true,
        },
        {
          label: "Consent to Terms",
          name: "consentTerms",
          type: "checkbox",
          optional: false,
        },
      ],
    },
  ];
  
  export default feedbackFormConfig;
  