import React from "react";
import AuditRuleDetailsHeader from "./components/header/Header";
import AuditRuleDetails from "./components/auditruledetails/AuditRuleDetails";
import { ToastContainer } from 'react-toastify';

const PowerApp = () => {
  return (
    <>
      {/* <AuditRuleDetailsHeader /> */}
      <ToastContainer position="top-center"/>
      <AuditRuleDetails />
    </>
  );
};

export default PowerApp; 