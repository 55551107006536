import React from "react";

const Archive = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-archive"
      viewBox="0 0 16 16"
    >
      <path d="M12.643 0c.73 0 1.317.588 1.317 1.318v1.311c0 .159-.13.286-.286.286H2.326a.287.287 0 0 1-.286-.286V1.318C2.04.589 2.627 0 3.357 0h9.286zM15.357 3H.643A.644.644 0 0 0 0 3.643v10.714C0 15.411.589 16 1.317 16h13.366c.728 0 1.317-.589 1.317-1.317V3.643A.644.644 0 0 0 15.357 3zM5 12V5h6v7H5z" />
    </svg>
  );
};

export default Archive;
