import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import "./DonutChart.css";

const DonutChart = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.label || "";
            const value = tooltipItem.raw || 0;
            return `${label}: ${value}%`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div
      style={{
        display: "",
        justifyContent: "center",
        alignItems: "center",
        height: "70%"
      }}
    >
      <div style={{ height: "70%" }}>
        <Doughnut data={data} options={options} />
      </div>
      <div>
        <ul style={{ padding: 8}} className="list-donut">
          {data.labels  
           .slice(showMore ? 10 : 0, showMore ? data.labels.length : 10)
            .map((label, index) => (
              <li
                key={index}
                style={{
                  marginBottom: "5px",
                  listStyleType: "none",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "10px",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "15px",
                    height: "15px",
                    backgroundColor: data.datasets[0].backgroundColor[index],
                    marginRight: "10px",
                    borderRadius: "50%",
                  }}
                ></span>
                {label}
              </li>
            ))}
        </ul>
        {data.labels.length > 10 && (
          <div
            onClick={toggleShowMore}
            style={{ cursor: "pointer"}}
            className="expand-arrow"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659" />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default DonutChart;
