import { BrowserRouter, useLocation } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./authContext/AuthContext";
import RoutesPage from "./routes/RoutesPage";
import Navbar from "./pages/navbar/Navbar";
import MainSidebar from "./pages/sidebar/MainSidebar";
import { useEffect, useState } from "react";
import { configData } from "./pages/navbar/config";
import ContextWrapper, { useChatContext } from "./context";
import ErrorModalPopup from "./ErrorModalPopup";

export const isRelatedRoute = (path) => {
  return configData.find((route) => route.path === path);
};

function App() {
  const location = useLocation();
  const { common } = useChatContext();
  const { mode, setMode, selectedCompanion, setSelectedCompanionState } =
    common;
  const [isModalVisible, setIsmodalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isRowClicked, setIsRowClicked] = useState(false);
  useEffect(() => {
    const activeRoute = isRelatedRoute(location.pathname);
    if (activeRoute) {
      setSelectedCompanionState(activeRoute.header);
    }
    const currentMode = sessionStorage.getItem("mode") ?? "DARK";
    setMode(currentMode);
  }, []);
  useEffect(() => {
    const handleSessionExpired = (event) => {
      const errorMessage = event.detail.errorMessage;
      setModalMessage(errorMessage);
      setIsmodalVisible(true);
    };
    window.addEventListener("tokenExpired", handleSessionExpired);
    return () => {
      window.removeEventListener("tokenExpired", handleSessionExpired);
    };
  }, []);
  const handleClose = () => {
    sessionStorage.clear();
    setIsmodalVisible(false);
    window.location.replace("/login");
  };
  const isPowerAppRoute = location.pathname === "/powerapp";

  return (
    <><AuthProvider>
      <div className={mode !== "DARK" && "d-flex h-100"}>
        {mode === "DARK" ? (
          <Navbar
            setMode={setMode}
            mode={mode}
            setSelectedCompanionState={setSelectedCompanionState}
            selectedCompanion={selectedCompanion}
          />
        ) : (
          <MainSidebar
            setMode={setMode}
            mode={mode}
            setSelectedCompanionState={setSelectedCompanionState}
            selectedCompanion={selectedCompanion}
          />
        )}
        {mode === "LIGHT" ? (
          <div className="w-80 p-4 overflow-auto hidden-scroll">
            {!isRowClicked && !isPowerAppRoute && (
              <h3 style={{ color: "#032F49" }}>{selectedCompanion}</h3>
            )}
            <RoutesPage
              setIsRowClicked={setIsRowClicked}
              isRowClicked={isRowClicked}
            />
          </div>
        ) : (
          <div className="w-75 m-auto h-100 hidden-scroll">
            <RoutesPage
              setIsRowClicked={setIsRowClicked}
              isRowClicked={isRowClicked}
            />
          </div>
        )}
      </div>
    </AuthProvider>
    <ErrorModalPopup show={isModalVisible} handleModalClose={handleClose} message={modalMessage}/></>
  );
}

export default App;
