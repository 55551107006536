import React, { useEffect, useState } from "react";
import "./Navbar.css";
import Logo from "../../common/assets/images/imdexlogo.png";
import axios from "axios";
import { BiLoader } from "react-icons/bi";
import { NavLink, useLocation } from "react-router-dom";
import { configData } from "./config";
import ArrowRight from "../../common/assets/svg/ArrowRight";
import { CircleImg } from "../../common/assets/svg/CircleImg";
import { ArrowDown } from "../../common/assets/svg/ArrowDown";
import { Link } from "react-router-dom";
import { useAuth } from "../../authContext/AuthContext";

function Navbar({setMode, mode,selectedCompanion, setSelectedCompanionState}) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { userEmail } = useAuth();

  const handleLogoutClick = () => {
    setLoading(true);
    const postapiEndpoint =
      "https://dhc2t1bkzb.execute-api.ap-southeast-2.amazonaws.com/llm_log_out/";
    const accessToken = sessionStorage.getItem("accessToken");
    const postpayload = {
      body: {
        session_token: accessToken,
      },
    };
    axios
      .post(postapiEndpoint, postpayload)
      .then((response) => {
        console.log("logout request successful:", response);
        sessionStorage.clear();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error in logout request:", error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCompanionClick = (companion) => {
    setSelectedCompanionState(companion);
  };
  const handleMode =()=> {
    const newMode = mode === "DARK" ? "LIGHT": "DARK"
    setMode(newMode)
    sessionStorage.setItem("mode", newMode)
  }
  

  return (
    <div className="landingpage-header">
      <div className="landing-header-top">
        <img className="landing-logo" src={Logo} alt="fff" />
        <div className="landing-account-info">
        <div className="d-flex">
              <span className="mode-color-nav">
                {mode === "DARK"
                  ? "Light Mode"
                  : "Dark Mode"}
              </span> 
              <div>
                <label class="switch">
                  <input
                    type="checkbox" 
                    checked={mode === "DARK"}
                    onChange={handleMode}
                  />
                  <span class="slider round light"></span>
                </label>
              </div>
            </div>
          <span className="landingpage-user-email">{userEmail}</span>
          <CircleImg />
          <div className="landingpage-arrow-down">
            <ArrowDown />
            <div className="landingpage-dropdown-content">
              <div>
                <span
                  className="landing-logout-btn"
                  onClick={handleLogoutClick}
                >
                  <ArrowRight />
                  {loading ? <BiLoader /> : "Logout"}
                </span>
                <Link to ="powerapp" onClick={()=>setSelectedCompanionState("Settings")}>
                  <span>Settings</span>
                </Link>
                <div>
                  <Link to="feedback" onClick={()=>setSelectedCompanionState("Feedback")}>
                    <span>Feedback</span>
                  </Link>
                </div>
                <div>
                  <Link to="user-feedbacks" onClick={()=>setSelectedCompanionState("User Feedback")}>
                    <span>User Feedback</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-header-bottom">
        <div className="selected-companion">{selectedCompanion}</div>

        <ul className="landing-companion-toggle-btn">
          {configData.map((route) => (
            <li
              // className={selectedCompanion === route.header ? "active" : ""}
              onClick={() => handleCompanionClick(route.header)}
            >
              <NavLink to={route.path}>{route.header}</NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
