import React, { useEffect, useState } from "react";
// import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import "./Page.css";
import AuditDetails from "../auditdetails/AuditDetails";
import AuditRows from "../audits/AuditRows";
import { initilaDetailsObj } from "../../utils/Constants";
import {
  getAuditRows,
  getFilteredAuditData,
  getPassedRecords,
  getPassedRecordsDetails,
  getauditData,
} from "../../api/Service";
import { Spinner } from "../../common/Helper";
import { useChatContext } from "../../../../context";

function Page({ isRowClicked, setIsRowClicked }) {
  const { webWorkFlow } = useChatContext();
  const {
    auditDetails,
    setAuditDetails
  } = webWorkFlow 
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [auditRows, setAuditRows] = useState([]);
  const [isRowsLoading, setIsRowsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [appliedFilters, setAppliedFilters] = useState({
    insurer_identifier: [],
    priority_value: [],
    status: [],
    file_type: [],
    load_id: [],
  });
  const [isP5Clicked, setIsP5Clicked] = useState(false);
  const [priorityVal5,setPriorityVal5] = useState(false);
  
  console.log(isP5Clicked, "isP5Clicked:::");

  const getAuditDetailsById = async (id, priority_value) => {
    setIsDetailsLoading(true);
    // if (isP5Clicked) {
    //   return getPassedRecordsDetails(id)
    //     .then((data) => {
    //       const details = data.audited_rows[0];
    //       setAuditDetails({ ...details, id });
    //     })
    //     .catch((err) => {
    //       console.error(err.message);
    //       throw new Error(err.message);
    //     })
    //     .finally(() => {
    //       setIsDetailsLoading(false);
    //     });
    // }
    // return 
      getauditData(id, priority_value)
      .then((data) => {
        console.log("DATA::::", data)
        const details = data.audited_rows[0];
        setAuditDetails({ ...details, id });
        return { ...details, id };
      })
      .catch((err) => {
        console.error(err.message);
        throw new Error(err.message);
      })
      .finally(() => {
        setIsDetailsLoading(false);
      });
  };
  const handleRowClick = (row) => {
    console.log("ROW::::::", row)
    getAuditDetailsById(row.id, row.priority_value);
    const index = auditRows.findIndex((data) => data.id === row.id);
    setSelectedIndex(index);
    setIsRowClicked(true);
    setPriorityVal5(row.priority_value===5?true:false);
  };
  const updatedAuditRows = (rows) => {
    setAuditRows(rows.reverse());
  };
  const getAuditRowsByFilter = async (filters) => {
    try {
      updatedAuditRows([]);
      setIsRowsLoading(true);
      setAuditDetails({});
      setAppliedFilters({ ...appliedFilters, ...filters });
      setHasMore(true);
      setIsP5Clicked(false);
      const appliedfilter = { ...appliedFilters, ...filters, pageNumber: 1 };
      const data = await getFilteredAuditData(appliedfilter);
      if (data.message?.audited_rows?.length) {
        setHasMore(true);
      }
      updatedAuditRows(data.message.audited_rows);
      setIsRowsLoading(false);
    } catch (err) {
      setIsRowsLoading(false);
      setHasMore(false);
    }
  };
  const handleRefreshClick = () => {
    setAuditDetails({});
    getAuditRowsData();
    window.location.reload();
  };
  const getAuditRowsData = (page = 1) => {
    setIsRowsLoading(true);
    getAuditRows(page ? page : 1, appliedFilters)
      .then((data) => {
        if (data.length === 0) {
          setHasMore(false);
        }
        setAuditRows([...auditRows, ...data]);
        setPage(page + 1);
      })
      .catch((err) => {
        console.error("AUDIROW_ERR::", err);
        setIsRowsLoading(false);
        setHasMore(false);
      })
      .finally(() => {
        setIsRowsLoading(false);
      });
  };
  const handlePreviousClick = () => {
    const index = selectedIndex - 1;
    getAuditDetailsById(auditRows[index].id, auditRows[index].priority_value);
    setSelectedIndex(index);
  };
  const handleNextClick = () => {
    const index = selectedIndex + 1;
    getAuditDetailsById(auditRows[index].id,auditRows[index].priority_value);
    setSelectedIndex(index);
  };
  const handleAuditDataUpdate = (id, status, priority_value) => {
    getAuditDetailsById(id, priority_value).then(() => {
      //getting
      const updatedRow = auditRows[selectedIndex];
      // updating
      updatedRow.id = id;
      updatedRow.audit_status = status;
      //replacing in the same index
      auditRows[selectedIndex] = updatedRow;
    });
  };
  // const handleP5PriorityClick = (page) => {
  //   getPassedRecords(page)
  //     .then((data) => {
  //       updatedAuditRows(data);
  //       setIsP5Clicked(true);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching passed records:", error);
  //     });
  // };
  useEffect(() => {
    getAuditRowsData();
    window.scrollTo(0, 0);
  }, []);
  console.log(auditDetails,"setIsRowClicked");
  return (
    <div className="main-page h-100">
      {isRowClicked ? (
        <>
          {isDetailsLoading ? (
            <Spinner />
          ) : (
            <AuditDetails
              selectedIndex={selectedIndex}
              length={auditRows.length}
              auditDetails={auditDetails}
              handlePreviousClick={handlePreviousClick}
              handleNextClick={handleNextClick}
              setAuditDetails={setAuditDetails}
              getUpdatedAuditDetails={handleAuditDataUpdate}
              getAuditRowsData={getAuditRowsByFilter}
              setIsRowClicked={setIsRowClicked}
              priorityVal5={priorityVal5}
            />
          )}
        </>
      ) : (
        <div className="audit-content">
          <Navbar
            updatedAuditRows={updatedAuditRows}
            handleRefreshClick={handleRefreshClick}
            setAuditDetails={setAuditDetails}
            setIsRowsLoading={setIsRowsLoading}
            getAuditRowsByFilter={getAuditRowsByFilter}
            setHasMore={setHasMore}
          />
          <div className="audits w-100 h-100">
            <AuditRows
              handleRowClick={handleRowClick}
              auditRows={auditRows}
              isRowsLoading={isRowsLoading}
              selectedIndex={selectedIndex}
              hasMore={hasMore}
              loadMore={() => getAuditRowsData(page)}
            />
            {/* <div className="sidebar">
              <Sidebar
                updatedAuditRows={updatedAuditRows}
                setAuditDetails={setAuditDetails}
                getAuditRowsByFilter={getAuditRowsByFilter}
                handleP5PriorityClick={() => handleP5PriorityClick(1)}
              />
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Page;
