import React, { useState, useEffect } from "react";
import "./CodingImprovements.css";
import MultiSelectDropdown from "../../components/multiselect-dropdown/MultiSelectDropdown";
import { BsInfoCircle } from "react-icons/bs";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getCodingImprovementsData } from "../../api/Service";
import InfiniteScroll from "../../components/infinite-scroll";

const headers = [
  { key: "drg_code", value: "drg Code" },
  {
    key: "principal_diagnosis_code",
    value: "Principal Diagnosis Code",
  },
  { key: "procedure_codes1", value: "Procedure Codes1" },
  { key: "procedure_codes2", value: "Procedure Codes2" },
  { key: "procedure_codes3", value: "Procedure Codes3" },
  { key: "procedure_codes4", value: "Procedure Codes4" },
  { key: "procedure_codes5", value: "Procedure Codes5" },
  { key: "procedure_codes6", value: "Procedure Codes6" },
  { key: "procedure_codes7", value: "Procedure Codes7" },
  { key: "procedure_codes8", value: "Procedure Codes8" },
  { key: "procedure_codes9", value: "Procedure Codes9" },
  { key: "procedure_codes10", value: "Procedure Codes10" },
  { key: "audit_rule_user_message", value: "Audit Rule User Message" },
  { key: "audit_drg_revenue", value: "Audit Drg Revenue" }, 
  { key: "episode_count", value: "Episode Count" },
];

const initialFiltersObj = {
  drgCode: [],
  principalDiagnosisCode: [],
  procedureCodes1: [],
  procedureCodes2: [],
  procedureCodes3: [],
  procedureCodes4: [],
  procedureCodes5: [],
  procedureCodes6: [],
  procedureCodes7: [],
  procedureCodes8: [],
  procedureCodes9: [],
  procedureCodes10: [],
  auditRuleUserMessage: [],
  auditDrgRevenue: [],
  coderName: [],
  episodeCount: []
};
const getObject = (option) => ({
  label: option,
  value: option,
});
const CodingImprovements = ({ filterOptions, loadId }) => { 
  const [filterToggle, setFilterToggle] = useState();
  const [filtersData, setFiltersData] = useState(initialFiltersObj);
  const [selectedFilters, setSelectedFilters] = useState(initialFiltersObj);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [potentialRevenueUplift, setPotentialRevenueUplift] = useState(0);
  const [revenuePrefindings, setRevenuePrefindings] = useState(0);
  console.log(tableData,"tabledata::")
  const percentageValue = 0.15 * 4000
  const formatToMillions = (num) => {
    return "$" +(num / 1_000_000).toFixed(2) + 'M';
  };
  const formatNumber = (num) => {
    return '$' + num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleFilterToggle = () => {
    setFilterToggle(!filterToggle);
  };
  const getData = (page = currentPage, loadMore) => {
    const body = {
      drg_code: selectedFilters.drgCode,
      principal_diagnosis_code: selectedFilters.principalDiagnosisCode,
      procedure_codes1: selectedFilters.procedureCodes1,
      procedure_codes2: selectedFilters.procedureCodes2,
      procedure_codes3: selectedFilters.procedureCodes3,
      procedure_codes4: selectedFilters.procedureCodes4,
      procedure_codes5: selectedFilters.procedureCodes5,
      procedure_codes6: selectedFilters.procedureCodes6,
      procedure_codes7: selectedFilters.procedureCodes7,
      procedure_codes8: selectedFilters.procedureCodes8,
      procedure_codes9: selectedFilters.procedureCodes9,
      procedure_codes10: selectedFilters.procedureCodes10,
      audit_rule_user_message: selectedFilters.auditRuleUserMessage,
      audit_drg_revenue: selectedFilters.auditDrgRevenue,
      coder_name: selectedFilters.coderName,
      episodeCount: selectedFilters.episode_count,
      load_id: loadId,
      pageNumber: page,
    }; 
    setIsLoading(true); 
    getCodingImprovementsData(body)
      .then((res) => {
        const { 
          query_for_recomendations,
          query_for_potential_revenue_uplift,
          query_for_pre_revenue_findings,
        } = res.message;

        if (loadMore) {
          setTableData([...tableData, ...query_for_recomendations]);
        } else {
          setTableData(query_for_recomendations);
        }
        setPotentialRevenueUplift(query_for_potential_revenue_uplift);
        setRevenuePrefindings(query_for_pre_revenue_findings)
        const newPage = page + 1;
        setCurrentPage(newPage);
        setHasMore(true);
      })
      .catch((err) => {
        setHasMore(false);
        console.error("CODING_IMPROVEMENTS_GET_DATA_ERR::", err.message);
        if (!loadMore) {
          setTableData([]);
        }
      })
      .finally(() => {
        console.log("GET_DATA::::FINALLY", page);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getData(1);
  }, [selectedFilters, loadId]);
  const handleSelectFilter = (name, selectedOptions) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [name]: selectedOptions?.map((option) => option.value),
    }));
  };
  useEffect(() => {
    if (Object.keys(filterOptions).length > 0) {
      const filtersObj = {
        drgCode: filterOptions.drg_code?.map(getObject),
        principalDiagnosisCode:
          filterOptions.principal_diagnosis_code?.map(getObject),
        procedureCodes1: filterOptions.procedure_codes1?.map(getObject),
        procedureCodes2: filterOptions.procedure_codes2?.map(getObject),
        procedureCodes3: filterOptions.procedure_codes3?.map(getObject),
        procedureCodes4: filterOptions.procedure_codes4?.map(getObject),
        procedureCodes5: filterOptions.procedure_codes5?.map(getObject),
        procedureCodes6: filterOptions.procedure_codes6?.map(getObject),
        procedureCodes7: filterOptions.procedure_codes7?.map(getObject),
        procedureCodes8: filterOptions.procedure_codes8?.map(getObject),
        procedureCodes9: filterOptions.procedure_codes9?.map(getObject),
        procedureCodes10: filterOptions.procedure_codes10?.map(getObject),
        auditRuleUserMessage:
          filterOptions.audit_rule_user_message?.map(getObject),
        auditDrgRevenue: filterOptions.audit_drg_revenue?.map(getObject),
        coderName: filterOptions.coder_name?.map(getObject),
      };
      setFiltersData(filtersObj);
    }
  }, [filterOptions]);
  const getHeatMapClassName = (value) => {
    console.log(value, "value:::");
    if (value >= 1 && value <= 4) return "HeatMapgreen";
    if (value >= 5 && value <= 9) return "HeatMapyellow";
    if (value > 9) return "HeatMaporange";
    return "";
  };
  return (
    <div className="coding-improvements-Wrapper">
      <div className="top-calculations">
        <div className="hospital-revenue-findings">
          <div style={{ display: "inline-block" }}>
            {" "}
            Hospital Revenue Pre-findings{" "}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Sum of Current DRG Revenue</Tooltip>}
            >
              <span style={{ cursor: "pointer" }}>
                <BsInfoCircle />
              </span>
            </OverlayTrigger>
          </div>
          <div style={{ color: "#032F49" }}>{`${formatNumber(revenuePrefindings)}.00`}</div>
        </div>
        <div className="Potential-revenue-uplift">
          <div style={{ display: "inline-block" }}>
            {" "}
            Potential Revenue Uplift{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  With an estimated average strike rate of 15% at an average DRG
                  uplift of $4K
                </Tooltip>
              }
            >
              <span style={{ cursor: "pointer" }}>
                <BsInfoCircle />
              </span>
            </OverlayTrigger>
          </div>
          <div style={{ color: "#032F49" }}>{formatToMillions(potentialRevenueUplift * percentageValue)}</div>
        </div>
      </div>
      <div className="codingimprovements-heading-wrapper">
        <h4 className="codingimprovements-heading">Coding Improvements</h4>
        <h4 className="codingimprovements-heading">
          Top 20 Recommended Changes
        </h4>
      </div>
      <div className="d-flex gap-2">
        <div className="codingimprovements-content-wrapper d-flex">
          <div className="coding-improvemets-table-wrapper">
            <div className="codingimprovements-table">
              <InfiniteScroll
                className="findings-table table-container"
                hasData={hasMore}
                loadMore={() => getData(currentPage, true)}
                isLoading={isLoading}
              >
                <table>
                  <thead>
                    <tr>
                      {headers.map((header) => (
                        <th key={header.key}>{header.value}</th>
                      ))}
                    </tr>
                  </thead>
                  {console.log("TABLE::DATA::::", tableData)}
                  <tbody>
                    {tableData?.map((row, rowIndex) => ( 
                      <tr key={rowIndex}>
                        {headers.map((header) => (
                          <td key={header.key} className={header.key === "episode_count" ? getHeatMapClassName(row.episode_count) : ""}>
                            <OverlayTrigger
                              placement="left"
                              overlay={<Tooltip>{row[header.key]}</Tooltip>}
                            >
                              <span>{row[header.key]}</span>
                            </OverlayTrigger>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
        <div className="d-flex mt-2 gap-1">
          <div onClick={handleFilterToggle} className="filters-text">
            <span>filters</span>
          </div>
          {filterToggle && (
            <div className="codingimprovements-filter-content p-2">
              <div className="filter-header d-flex gap-5">
                <h6>Filter Pane</h6>
                <span
                  className="back-arrow-filter"
                  onClick={handleFilterToggle}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-return-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"
                    />
                  </svg>
                </span>
              </div>
              <div className="text-start mb-1">
                DRG
                <MultiSelectDropdown
                  options={filtersData.drgCode}
                  onSelect={(options) => handleSelectFilter("drgCode", options)}
                />
              </div>
              <div className="text-start mb-1">
                Principal Diagnosis Code
                <MultiSelectDropdown
                  options={filtersData.principalDiagnosisCode}
                  onSelect={(options) =>
                    handleSelectFilter("principalDiagnosisCode", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Procedure Code1
                <MultiSelectDropdown
                  options={filtersData.procedureCodes1}
                  onSelect={(options) =>
                    handleSelectFilter("procedureCodes1", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Procedure Code2
                <MultiSelectDropdown
                  options={filtersData.procedureCodes2}
                  onSelect={(options) =>
                    handleSelectFilter("procedureCodes2", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Procedure Code3
                <MultiSelectDropdown
                  options={filtersData.procedureCodes3}
                  onSelect={(options) =>
                    handleSelectFilter("procedureCodes3", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Procedure Code4
                <MultiSelectDropdown
                  options={filtersData.procedureCodes4}
                  onSelect={(options) =>
                    handleSelectFilter("procedureCodes4", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Procedure Code5
                <MultiSelectDropdown
                  options={filtersData.procedureCodes5}
                  onSelect={(options) =>
                    handleSelectFilter("procedureCodes5", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Procedure Code6
                <MultiSelectDropdown
                  options={filtersData.procedureCodes6}
                  onSelect={(options) =>
                    handleSelectFilter("procedureCodes6", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Procedure Code7
                <MultiSelectDropdown
                  options={filtersData.procedureCodes7}
                  onSelect={(options) =>
                    handleSelectFilter("procedureCodes7", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Procedure Code8
                <MultiSelectDropdown
                  options={filtersData.procedureCodes8}
                  onSelect={(options) =>
                    handleSelectFilter("procedureCodes8", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Procedure Code9
                <MultiSelectDropdown
                  options={filtersData.procedureCodes9}
                  onSelect={(options) =>
                    handleSelectFilter("procedureCodes9", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Procedure Code10
                <MultiSelectDropdown
                  options={filtersData.procedureCodes10}
                  onSelect={(options) =>
                    handleSelectFilter("procedureCodes10", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Audit Rule User Message
                <MultiSelectDropdown
                  options={filtersData.auditRuleUserMessage}
                  onSelect={(options) =>
                    handleSelectFilter("auditRuleUserMessage", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Audit DRG Revenue
                <MultiSelectDropdown
                  options={filtersData.auditDrgRevenue}
                  onSelect={(options) =>
                    handleSelectFilter("auditDrgRevenue", options)
                  }
                />
              </div>
              <div className="text-start mb-1">
                Coded By
                <MultiSelectDropdown
                  options={filtersData.coderName}
                  onSelect={(options) =>
                    handleSelectFilter("coderName", options)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CodingImprovements;
